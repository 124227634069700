import { Link } from "@mui/material";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getRegionColor } from "../../util/ColorUtil";
import { getLanguage } from "../../util/PageUtil";
import { Constants } from "../../util/Constants";
import {applyTruncationForScreen, isItUndefined, isLinkAbsolute, isMobile, isTablet, stripHtml, marketoOptions as options, getFacilityForMetro, getAlgoliaIndex, handleGoTo
} from "../../util/DataUtil";
import { excludeMetroDuplicateIDs, handleSeeMoreFacility, handleSeeMoreMetro, renderNoResult } from "../../util/LocationsUtil";
import { formatCommaSeparator } from "../../util/RegexUtil";
import { sortMetros } from "../../util/MapsUtil";
import { getMetricSystemForLanguage } from "../../util/LocationsUtil";

const MapFilter = dynamic(() => import('../map/search/MapFilter'));
const Map = dynamic(() => import('../map/map'));

const TemplateMetroMap = ({children, metroData, width, allMetros, lang, global}) =>{
    const [facilityData, setFacilityData] = useState(metroData?.facilities);
    const [query, setQuery] = useState('');
    const [filterProps, setFilterProps] = useState([]);
    const [languageFilter, setLanguageFilter] = useState([])
    const [seeMoreMetro, setSeeMoreMetro] = useState(handleSeeMoreMetro(width));
    const [seeMoreFacility, setSeeMoreFacility] = useState(handleSeeMoreFacility(width));
    const [currentZoom, setCurrentZoom] = useState(children?.field_map_minimum_zoom[0]?.value ?  Number(children?.field_map_minimum_zoom[0].value) : 12);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [algoliaData, setalgoliaData] = useState([]);
    const router = useRouter();
    const metricSystem = getMetricSystemForLanguage(lang);

    useEffect(() => {
        getFromAlgolia(query);
    },[query])

    useEffect(() => {
        setFacilityData(metroData?.facilities);
        setQuery('');
    },[metroData])

    let facetFiltersArray = JSON.stringify([]);
    let tempLng = JSON.stringify("search_api_language:" + lang);

    const getFromAlgolia = async (query) => {
        if(query.length<2){
            return
        }
        // const url = "https://O2VJB2KMPB-dsn.algolia.net/1/indexes/alllocationsprod/?query=" + query + `&hitsPerPage=${1000}`;
        const url = "https://O2VJB2KMPB-dsn.algolia.net/1/indexes/alllocations" + getAlgoliaIndex() + "?query=" + encodeURIComponent(query) + '&facetFilters=[' + facetFiltersArray + ',' + tempLng + ',["status:true"]]' + `&hitsPerPage=${1000}`;
        const response = await fetch(url, options)
            .then((response) => response.json())
            .then((algolia) => {
                setalgoliaData(algolia.hits);
            });
    }

    useEffect(() => {
        if(isMobile(width) || isTablet(width)){
            return;
        }
        let input = document.getElementById('search-input');

        if(!input){
            return;
        }

        input.setAttribute('size',input.getAttribute('placeholder').length);
        input.style.width = `${120}%`
    },[])

    useEffect(() => {
        let element = document.getElementsByClassName('bottom-part');
        let gradientElement = document.getElementsByClassName('gradient');
        for (let index = 0; index < element.length; index++) {
            for (let i = 0; i < gradientElement.length; i++) {
                if(index == i){
                    if(element[index].scrollLeft == 0){
                        gradientElement[i].style.display = 'block';
                    }else {
                        gradientElement[i].style.display = 'none';
                    }
                }
            }
        }
    },[isScrolled])

    const handleSearch = (search) => {
        setQuery(search.length !== 0 ? search : '');
        setIsFilterActive(false);
        setIsSearchActive(true);
        // setFilterProps([]);
    }

    const triggerFilter = (filters) => {
        setFilterProps(filters);

        let searchFilters = [];
        for(let filter of filters){
            searchFilters.push(filter?.field_source_translation);
        }
    }

    useEffect(() => {
        getSearchTerms(query, metroData?.facilities, filterProps);
    },[query, filterProps])

    const clearAll = () => {
        setFilterProps([])
        triggerFilter([])
        setLanguageFilter([])
        setQuery('')
    }

    const clearQuery = () => {
        setQuery('');
        setIsSearchActive(false);
        setIsFilterActive(true);
    }

    const getAvaliableCertifications = (facilities) => {
        if(!facilities || !facilities.length) return;
        let result = [];

        facilities.map((item) => {
            if(item.field_certifications_compliance.length == 0){
                return;
            }
            item.field_certifications_compliance.map(x => {
                result.push(x)
            })
        })

        return result.filter((v,i,a)=>a.findIndex(v2=>(v2.tid===v.tid))===i);
    }

    // const renderNoResult = () => {
    //     return <div className={'no-result'}>
    //         <span className="no-result-text">No results were found.</span>
    //         <div className="help-text">
    //             <div className={'clear-all'} onClick={() => { clearAll() }}>Clear Results</div>
    //             <span> -or- </span>
    //             <span className="talk">Talk to one of our Data Center Specialists</span>
    //         </div>

    //     </div>
    // }

    const getDataCentresText = (count) =>{
        if(count == 0){
            return ''
        }
        if(count == 1){
            return 'Data center'
        }
        if(count > 1){
            return 'Data centers'
        }
    }

    const getSearchTerms = (query, data, filters) => {
        if(query == '' && filters.length == 0){
            setFacilityData(data);
        }

        let filteredFacilities = [];

        if(isSearchActive){
            filteredFacilities = data.filter(e => e.field_address_location[0]?.value.toLowerCase().includes(query.toLowerCase()) || e?.field_site_code_location[0]?.value.toLowerCase().startsWith(query.toLowerCase()));
        }else if(isFilterActive) {
            if(data.length == 0) return;

            data.map(item => {
                let i = false;
                filters.map(filter => {
                    item.field_certifications_compliance.map(e => {
                        if(e?.name == filter.name){
                            i = true;
                        }
                    })
                })
                if(i){
                    filteredFacilities.push(item);
                }
            })
            setFacilityData(filteredFacilities);
        }

        if(filteredFacilities.length == 0){
            setFacilityData(data);
            return;
        }
    }

    const renderSearchResults = (query, filters) => {
        if(!query || query.length < 2){
            return;
        }

    
        let filteredMetros;
        let filteredFacilities;
        if(isSearchActive){
            filteredMetros = algoliaData.filter(x => x.type === 'metro' && x?.objectID?.endsWith(lang));

            filteredFacilities = algoliaData.filter(e => e.type === "location" && e?.objectID?.endsWith(lang));
            let metroResult = [];
            filteredMetros?.map(metro => {
                filteredFacilities.map(facility => {
                    if(facility.facility_metro == metro.metro_name){
                        metroResult.push(metro)
                    }
                })
            })
            if(filteredFacilities?.length > 0){
                allMetros?.map(metro => {
                    filteredFacilities.map(facility => {
                        if(facility.facility_metro == metro.metro){
                            metroResult.push(metro)
                        }
                    })
                })
            }
            filteredMetros = [...new Set(metroResult), ...new Set(filteredMetros)];
            filteredMetros = [...new Set(filteredMetros)]

            filteredMetros = excludeMetroDuplicateIDs(filteredMetros);

            let metrosHtml = [];
            let facilitiesHtml = [];

            if(algoliaData?.length == 0){
                return renderNoResult(global, clearAll);
            }

            let orderedMetros = sortMetros(filteredMetros, query);

            // filteredMetros.map((item, key) => {
            orderedMetros.map((item, key) => {
                metrosHtml.push(<div onClick={() => item?.url ? handleGoTo(item?.url, router, getLanguage(window.location.hostname)) : handleGoTo(item['url-alias'], router, getLanguage(window.location.hostname))} className="div-metro-map-link metro" key={`metro-index-${key}`}>
                <img alt="search" src="/images/search-image.png"/>
                <div className="right">
                    <span className="title">{item?.metro_name ? item?.metro_name : item?.metro}</span>
                    <span className="sub-title">
                    {applyTruncationForScreen(typeof item?.field_intro === 'string' ? item?.field_intro : isItUndefined(item?.field_intro),90,67,67,67)}
                    </span>
                </div>
            </div>)
            })
            let numberOfFacilities = 0;
            filteredFacilities.map((item) => {
                numberOfFacilities++;
                facilitiesHtml.push(<div onClick={() => handleGoTo(item?.url, router, getLanguage(window.location.hostname))} key={`facility-index-${numberOfFacilities}`} className={`div-metro-map-link facility ${getRegionColor(item)}`}>
                <img alt="pin" src="/images/map-pin.svg"/>

                <div className="right">
                    <span className="title">{item?.facility_metro} {item?.field_site_code_location}</span>
                    {/* <Link href={`http://maps.google.com/?q=${stripHtml(isItUndefined(item.field_address_location))}`} passHref> */}
                        <a target="_blank" className="a-regional-map-link address">{stripHtml(item?.facility_address)}</a>
                    {/* </Link> */}
                </div>
            </div>)
            })

            return getHtmlResult(metrosHtml, facilitiesHtml, metrosHtml.length, numberOfFacilities);

        }

        if(filters.length || !filteredMetros?.length){
            return;
        }


        if(algoliaData?.length == 0){
            return renderNoResult(clearAll);
        }

        let metrosHtml = [];
        let facilities = [];
        let facilitiesHtml = [];

        filteredMetros.map((item, key) => {
            if(getFacilityForMetro(item?.field_metro_name, filteredFacilities)){
                facilities.push(...getFacilityForMetro(item?.field_metro_name, filteredFacilities))
            }
            metrosHtml.push(
            <div onClick={() => router.push(item?.url)} className="div-metro-map-link metro" key={`metro-index-${key}`}>
                <img alt="search" src="/images/search-image.png"/>
                <div className="right">
                    <span className="title">{item?.metro_name}</span>
                    <span className="sub-title">
                    {applyTruncationForScreen(item?.field_intro,90,67,67,67)}
                    </span>
                </div>
            </div>)
        })

        facilities =  [...new Set(facilities)];

        let numberOfFacilities = 0;
        facilities.map((item) => {
            numberOfFacilities++;
            facilitiesHtml.push(<div onClick={() => router.push(item?.url)} key={`facility-index-${numberOfFacilities}`} className={`div-metro-map-link facility ${getRegionColor(item)}`}>
                <img alt="pin" src="/images/map-pin.svg"/>

                <div className="right">
                    <span className="title">{item?.facility_metro} {item?.field_site_code_location}</span>
                    {/* <Link href={`http://maps.google.com/?q=${stripHtml(isItUndefined(item.field_address_location))}`} passHref> */}
                        <a target="_blank" className="a-regional-map-link address">{stripHtml(item?.facility_address)}</a>
                    {/* </Link> */}
                </div>
            </div>)
        })
        return getHtmlResult(metrosHtml, facilitiesHtml, metrosHtml.length, numberOfFacilities);
    }

    const getHtmlResult = (metros,facilities, numberOfMetros, numberOfFacilities) => {
        facilities = facilities.slice(0,seeMoreFacility);
        metros = metros.slice(0, seeMoreMetro)
        return <>
            <div className="metro-results">
                <span className="regional-result-header">Metro ({(numberOfMetros)})</span>
                {metros}
                {
                    metros.length !== numberOfMetros &&
                    <div className={'see-more'} onClick={() => setSeeMoreMetro(seeMoreMetro + handleSeeMoreMetro(width))}>See More</div>
                }
            </div>
            <hr />
            <div className="facility-results">
                <span className="regional-result-header">Facility ({(numberOfFacilities)})</span>
                <div className="facilities">
                    {facilities}
                </div>
                {
                    facilities.length !== numberOfFacilities &&
                    <div className={'see-more'} onClick={() => setSeeMoreFacility(seeMoreFacility + handleSeeMoreFacility(width))}>See More</div>
                }
            </div>
        </>
    }

    const renderDataCenteres = (data) => {
        if(!data || !data.length) return;

        return data.map((item, key) => {
            let address = stripHtml(item?.field_address_location[0]?.value);
            let formattedAddres = address?.replace('-', '&#8209;');

            return <div key={`facility-index-${key}`} className="wrapper">
            <Link href={item['url-alias'] ? item['url-alias'] : ''} legacybehavior="true">
                <a target={isLinkAbsolute(item['url-alias']) ? '_blank' : '_self'} className="metro a-metro-map-link">
                    <div className="top-part">
                            <div className="top-left">
                                <span className="title">{item?.field_facility_location[0]?.name} {item?.field_site_code_location[0]?.value}</span>
                                <span dangerouslySetInnerHTML={{__html: formattedAddres}} className="sub-title" />
                            </div>
                            <img alt="arrow" className={`arrow ${getRegionColor(metroData?.locations)}`} src="/images/diagonal-arrow.svg" />
                    </div>
                    <div onScroll={() => setIsScrolled(!isScrolled)} className="bottom-part">
                        {metricSystem == 'Imperial' 
                        ? 
                            <>
                                {isItUndefined(item?.field_total_building_size_in_ft2) && <span>{formatCommaSeparator(isItUndefined(item?.field_total_building_size_in_ft2))} ft <sup>2</sup></span>}
                                {isItUndefined(item?.field_total_building_size_in_m2) && <span>{formatCommaSeparator(isItUndefined(item?.field_total_building_size_in_m2))} m <sup>2</sup></span>}
                            </>
                        :
                            <>
                                {isItUndefined(item?.field_total_building_size_in_m2) && <span>{formatCommaSeparator(isItUndefined(item?.field_total_building_size_in_m2))} m <sup>2</sup></span>}
                                {isItUndefined(item?.field_total_building_size_in_ft2) && <span>{formatCommaSeparator(isItUndefined(item?.field_total_building_size_in_ft2))} ft <sup>2</sup></span>}                        
                            </>
                        }                        
                        {/* {isItUndefined(item.field_utility_power_capacity) && <span>{formatCommaSeparator(isItUndefined(item.field_utility_power_capacity))} kW</span>} */}
                        {/* {isItUndefined(item.field_ups_redundancy) && <span>{isItUndefined(item.field_ups_redundancy)} Cooling</span>} */}
                        {isItUndefined(item?.field_cooling_plant_redundancy) && <span>{isItUndefined(item?.field_cooling_plant_redundancy)} Cooling</span>}
                        <div className="gradient"></div>
                    </div>
                </a>
            </Link>
            </div>

        })
    }

    const calculateMapCenter = (data) => {
        if(!data || !data.length) return;

        let latitudes = [];
        let longitudes = [];

        data.map(e => {
            let latitude = Number(e?.field_latitude[0]?.value);
            let longitude = Number(e?.field_longitude[0]?.value);

            if(!latitude || !longitude) return;

            latitudes.push(latitude);
            longitudes.push(longitude);
        })

        const totalLatitude = (latitudes.reduce((total,currentItem) =>  total = total + currentItem / latitudes.length , 0 ));

        const totalLongitude = (longitudes.reduce((total,currentItem) =>  total = total + currentItem / longitudes.length , 0 ));

        return {
            latitude: totalLatitude,
            longitude: totalLongitude
        }
    }

    const getDataForMetroMarkers = (data) => {
        if(!data || !data.length) return;

        let result = [];

        data.map((item) => {
            if(!item?.field_latitude[0]?.value || !item?.field_longitude[0]?.value || isNaN(item?.field_latitude[0]?.value) || isNaN(item?.field_longitude[0]?.value)) return;
            result.push({
                latitude: Number(item?.field_latitude[0]?.value),
                longitude: Number(item?.field_longitude[0]?.value),
                regionColor: getRegionColor(metroData),
                link: item['url-alias'],
                text: isItUndefined(item?.field_site_code_location)
            })
        })
        return result;
    }

    return <div className="template-metro-map">
        <MapFilter
            lang={lang}
            clearQuery={clearQuery} isSearchActive={isSearchActive} isFilterActive={isFilterActive} setIsSearchActive={setIsSearchActive} setIsFilterActive={setIsFilterActive}
            data={[{id:1,name:'Certifications', description: '', children: getAvaliableCertifications(facilityData)}]}
            searchQuery={query} onSearchChange={handleSearch} setQuery={setQuery}
            filterProps={filterProps} triggerFilter={triggerFilter} clearAll={clearAll}
            search={children?.field_search_text[0]?.value} title={children?.field_filter_text[0]?.value}
            languageFilter={languageFilter} setLanguageFilter={setLanguageFilter}
            screenWidth={width}
            cancelText={isItUndefined(global?.field_cancel_text_translation)}
            applyFilterText={isItUndefined(global?.field_apply_filters_text)}
            clearAllText={isItUndefined(global?.field_clear_all_text)}
            selectedText={isItUndefined(global?.field_selected_text)}
            seeMoreText={isItUndefined(global?.field_see_more_text)}
            seeLessText={isItUndefined(global?.field_see_less)}
        />
        <div className="inner-container">
            {typeof renderSearchResults(query, filterProps) == 'object' && <div className="search-result-container">
                {renderSearchResults(query, filterProps)}
            </div>}
        </div>

        <div className="regional-map-container">
            <div className="metro-container">
                <div className="metro-header">
                    <div className="metro-inner-container">
                        <div className="metro-wrapper">
                            <span>{facilityData.length} {children?.field_title[0]?.value ? children?.field_title[0]?.value : getDataCentresText(facilityData.length)}</span>
                        </div>
                    </div>
                </div>
                <div className="metro-wrapper">
                    <div className="metro-inner-container">
                        {renderDataCenteres(facilityData)}
                    </div>
                </div>
            </div>
            <div className="map-container">
                <Map
                    blockTypeClass={'metro-map'} metroMap={true}
                    currentZoom={currentZoom} setCurrentZoom={setCurrentZoom}
                    maxZoom={Number(children?.field_map_maximum_zoom[0]?.value)}
                    latitude={calculateMapCenter(facilityData)?.latitude} longitude={calculateMapCenter(facilityData)?.longitude}
                    renderMarkers={getDataForMetroMarkers(facilityData)} />
            </div>
        </div>
    </div>
}

export default TemplateMetroMap;
